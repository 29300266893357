import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const LandingPage = () => {
  const [isYearly, setIsYearly] = useState(false);

  const togglePricing = () => {
    setIsYearly(!isYearly);
  };

  return (
    <div className="bg-gray-100 min-h-screen">
      {/* Hero Section */}
      <div className="bg-gradient-to-r from-[#2c3e50] to-[#34495e] py-20 px-4">
        <div className="max-w-7xl mx-auto text-center">
          <h1 className="text-3xl md:text-5xl font-bold text-white mb-4">
            Transform Your Meetings into Actionable Insights with Reekap
          </h1>
          <p className="text-lg md:text-xl text-gray-200 mb-8">
            AI-powered recording, transcription, and summarization for smarter project management
          </p>
          <div className="flex justify-center space-x-4">
            <Link to="/register" className="bg-[#38b6ff] hover:bg-[#2980b9] text-white py-3 px-6 rounded-lg font-semibold transition-all duration-300 ease-in-out hover:-translate-y-0.5 hover:shadow-md focus:outline-none focus:ring-2 focus:ring-[#38b6ff] focus:ring-opacity-50">
              Start Free Trial
            </Link>
            <button className="bg-white text-[#2c3e50] py-3 px-6 rounded-lg font-semibold transition-all duration-300 ease-in-out hover:-translate-y-0.5 hover:shadow-md focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50">
              Watch Demo
            </button>
          </div>
          <div className="mt-12">
            <img src="../assets/images/app_dashboard.png" alt="Reekap in action" className="w-full rounded-lg shadow-lg" />
          </div>
        </div>
      </div>

      {/* Feature Highlights */}
      <div className="max-w-7xl mx-auto py-16 px-4">
        <h2 className="text-3xl font-bold text-[#2c3e50] mb-12 text-center">
          Powerful Features to Revolutionize Your Meetings
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          <div className="bg-white rounded-lg shadow-md p-8 border border-[#38b6ff] transition-all duration-300 hover:-translate-y-1 hover:shadow-xl">
            <img src="../assets/images/record-icon.svg" alt="Record Meetings" className="w-16 h-16 mb-4" />
            <h3 className="text-xl font-semibold mb-4 text-[#2c3e50]">Record Meetings</h3>
            <p className="text-gray-600">
              Effortlessly capture every detail with one-click recording on any device.
            </p>
          </div>
          <div className="bg-white rounded-lg shadow-md p-8 border border-[#38b6ff] transition-all duration-300 hover:-translate-y-1 hover:shadow-xl">
            <img src="../assets/images/transcribe-icon.svg" alt="Transcribe Audio" className="w-16 h-16 mb-4" />
            <h3 className="text-xl font-semibold mb-4 text-[#2c3e50]">Transcribe Audio</h3>
            <p className="text-gray-600">
              Convert speech to text with industry-leading accuracy powered by GPT API.
            </p>
          </div>
          <div className="bg-white rounded-lg shadow-md p-8 border border-[#38b6ff] transition-all duration-300 hover:-translate-y-1 hover:shadow-xl">
            <img src="../assets/images/summarize-icon.svg" alt="Generate Summaries" className="w-16 h-16 mb-4" />
            <h3 className="text-xl font-semibold mb-4 text-[#2c3e50]">Generate Summaries</h3>
            <p className="text-gray-600">
              Get concise, AI-generated summaries highlighting key points and action items.
            </p>
          </div>
          <div className="bg-white rounded-lg shadow-md p-8 border border-[#38b6ff] transition-all duration-300 hover:-translate-y-1 hover:shadow-xl">
            <img src="../assets/images/minutes-icon.svg" alt="Create Meeting Minutes" className="w-16 h-16 mb-4" />
            <h3 className="text-xl font-semibold mb-4 text-[#2c3e50]">Create Meeting Minutes</h3>
            <p className="text-gray-600">
              Automatically generate structured meeting minutes for easy follow-up.
            </p>
          </div>
        </div>
      </div>

      {/* How It Works */}
      <div className="bg-gradient-to-r from-[#2c3e50] to-[#34495e] bg-opacity-90 py-16 px-4">
        <div className="max-w-7xl mx-auto text-center">
          <h2 className="text-3xl font-bold text-white mb-12">How Reekap Works</h2>
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="md:w-1/5 mb-8 md:mb-0">
              <img src="../assets/images/record-step.svg" alt="Record" className="mx-auto mb-4" />
              <h3 className="text-xl font-semibold text-white">Record</h3>
              <p className="text-gray-300">Capture your meeting audio with one click</p>
            </div>
            <div className="md:w-1/5 mb-8 md:mb-0">
              <img src="../assets/images/transcribe-step.svg" alt="Transcribe" className="mx-auto mb-4" />
              <h3 className="text-xl font-semibold text-white">Transcribe</h3>
              <p className="text-gray-300">Convert speech to text with high accuracy</p>
            </div>
            <div className="md:w-1/5 mb-8 md:mb-0">
              <img src="../assets/images/analyze-step.gif" alt="Analyze" className="mx-auto mb-4" />
              <h3 className="text-xl font-semibold text-white">Analyze</h3>
              <p className="text-gray-300">Process content using advanced AI</p>
            </div>
            <div className="md:w-1/5">
              <img src="../assets/images/summarize-step.svg" alt="Summarize" className="mx-auto mb-4" />
              <h3 className="text-xl font-semibold text-white">Summarize</h3>
              <p className="text-gray-300">Generate insights and action items</p>
            </div>
          </div>
        </div>
      </div>

      {/* Use Cases */}
      <div className="max-w-7xl mx-auto py-16 px-4">
        <h2 className="text-3xl font-bold text-[#2c3e50] mb-12 text-center">
          Reekap for Every Team
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="bg-white rounded-lg shadow-md p-8 transition-all duration-300 hover:-translate-y-1 hover:shadow-xl">
            <img src="../assets/images/team-meetings.svg" alt="Team Meetings" className="w-16 h-16 mb-4" />
            <h3 className="text-xl font-semibold mb-4 text-[#2c3e50]">Team Meetings</h3>
            <p className="text-gray-600">
              Enhance collaboration and ensure everyone stays on the same page.
            </p>
          </div>
          <div className="bg-white rounded-lg shadow-md p-8 transition-all duration-300 hover:-translate-y-1 hover:shadow-xl">
            <img src="../assets/images/client-calls.svg" alt="Client Calls" className="w-16 h-16 mb-4" />
            <h3 className="text-xl font-semibold mb-4 text-[#2c3e50]">Client Calls</h3>
            <p className="text-gray-600">
              Never miss important details or commitments in client conversations.
            </p>
          </div>
          <div className="bg-white rounded-lg shadow-md p-8 transition-all duration-300 hover:-translate-y-1 hover:shadow-xl">
            <img src="../assets/images/workshops.svg" alt="Workshops" className="w-16 h-16 mb-4" />
            <h3 className="text-xl font-semibold mb-4 text-[#2c3e50]">Workshops</h3>
            <p className="text-gray-600">
              Capture and distill insights from lengthy brainstorming sessions.
            </p>
          </div>
        </div>
      </div>

      {/* Key Benefits */}
      <div className="bg-gray-200 py-16 px-4">
        <div className="max-w-7xl mx-auto">
          <h2 className="text-3xl font-bold text-[#2c3e50] mb-12 text-center">
            Why Choose Reekap?
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="text-center">
              <h3 className="text-2xl font-semibold mb-4 text-[#2c3e50]">Save Time</h3>
              <p className="text-gray-600">Reduce meeting follow-up time by up to 80%</p>
            </div>
            <div className="text-center">
              <h3 className="text-2xl font-semibold mb-4 text-[#2c3e50]">Improve Clarity</h3>
              <p className="text-gray-600">Ensure everyone understands key decisions and action items</p>
            </div>
            <div className="text-center">
              <h3 className="text-2xl font-semibold mb-4 text-[#2c3e50]">Boost Productivity</h3>
              <p className="text-gray-600">Focus on execution instead of note-taking</p>
            </div>
          </div>
        </div>
      </div>

      {/* Social Proof */}
      <div className="max-w-7xl mx-auto py-16 px-4">
        <h2 className="text-3xl font-bold text-[#2c3e50] mb-12 text-center">
          Trusted by employees of:
        </h2>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-8 items-center">
          <img src="../assets/images/client-logo-1.svg" alt="Client Logo" className="h-12" />
          <img src="../assets/images/client-logo-2.svg" alt="Client Logo" className="h-12" />
          <img src="../assets/images/client-logo-3.svg" alt="Client Logo" className="h-12" />
          <img src="../assets/images/client-logo-4.svg" alt="Client Logo" className="h-12" />
        </div>
        <div className="mt-12">
          <blockquote className="text-xl italic text-center text-gray-600 relative p-5 bg-white rounded-lg shadow-md">
            <span className="absolute top-0 left-0 text-6xl text-[#38b6ff] opacity-20">"</span>
            "Reekap has transformed how we conduct and follow up on meetings. It's an indispensable tool for our team."
            <footer className="text-sm mt-2">- Quest Moffat, CEO of Kadogo</footer>
          </blockquote>
        </div>
      </div>

      {/* CTA */}
      <div className="bg-gradient-to-r from-[#2c3e50] to-[#34495e] bg-opacity-90 py-16 px-4">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-3xl font-bold text-white mb-8">
            Ready to Revolutionize Your Meetings?
          </h2>
          <p className="text-xl text-white mb-8">
            Join thousands of teams already using Reekap to boost productivity and clarity.
          </p>
          <Link to="/register" className="bg-white text-[#38b6ff] py-3 px-8 rounded-lg font-semibold text-lg hover:bg-gray-100 transition-all duration-300 ease-in-out hover:-translate-y-0.5 hover:shadow-md focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50">
            Start Your Free Trial
          </Link>
        </div>
      </div>

      {/* Pricing */}
      <div className="max-w-7xl mx-auto py-16 px-4">
        <h2 className="text-3xl font-bold text-[#2c3e50] mb-12 text-center">
          Choose the Perfect Plan for Your Needs
        </h2>
        <div className="flex justify-center mb-8">
          <div className="bg-gray-200 rounded-full p-0.5">
            <button
              onClick={togglePricing}
              className={`px-4 py-2 rounded-full transition-all duration-300 ${!isYearly ? 'bg-[#38b6ff] text-white' : 'text-gray-700'} focus:outline-none focus:ring-2 focus:ring-[#38b6ff] focus:ring-opacity-50`}
            >
              Monthly
            </button>
            <button
              onClick={togglePricing}
              className={`px-4 py-2 rounded-full transition-all duration-300 ${isYearly ? 'bg-[#38b6ff] text-white' : 'text-gray-700'} focus:outline-none focus:ring-2 focus:ring-[#38b6ff] focus:ring-opacity-50`}
            >
              Yearly
            </button>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="bg-white text-[#2c3e50] rounded-lg shadow-md p-8 border border-[#38b6ff] transition-all duration-300 hover:-translate-y-1 hover:shadow-xl">
            <h3 className="text-xl font-semibold mb-4">FREE</h3>
            <p className="text-gray-600 mb-4">month</p>
            <ul className="list-disc list-inside mb-6 text-gray-600">
              <li>30 mins / month</li>
            </ul>
            <Link to="/register" className="bg-[#38b6ff] hover:bg-[#2980b9] text-white py-2 px-4 rounded-lg inline-block">
              Register
            </Link>
          </div>
          <div className="bg-white text-[#2c3e50] rounded-lg shadow-md p-8 border border-[#38b6ff] transition-all duration-300 hover:-translate-y-1 hover:shadow-xl">
            <h3 className="text-xl font-semibold mb-4">STARTER</h3>
            <p className="text-gray-600 mb-4">{isYearly ? '89.99 USD / year' : '9.99 USD / month'}</p>
            <ul className="list-disc list-inside mb-6 text-gray-600">
              <li>600 mins / month</li>
            </ul>
            <Link to="/register" className="bg-[#38b6ff] hover:bg-[#2980b9] text-white py-2 px-4 rounded-lg inline-block">
              Register
            </Link>
          </div>
          <div className="bg-white text-[#2c3e50] rounded-lg shadow-md p-8 border border-[#38b6ff] transition-all duration-300 hover:-translate-y-1 hover:shadow-xl">
            <h3 className="text-xl font-semibold mb-4">Pro</h3>
            <p className="text-gray-600 mb-4">{isYearly ? '159.99 USD / year' : '19.99 USD / month'}</p>
            <ul className="list-disc list-inside mb-6 text-gray-600">
              <li>1,500 mins / month</li>
            </ul>
            <Link to="/register" className="bg-[#38b6ff] hover:bg-[#2980b9] text-white py-2 px-4 rounded-lg inline-block">
              Register
            </Link>
          </div>
        </div>
        <div className="text-center mt-8">
          <Link to="/plans" className="bg-[#2c3e50] hover:bg-[#34495e] text-white py-2 px-4 rounded-lg inline-block">
            Learn More
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
