// Ensure all necessary imports are here
import React, { useEffect } from 'react'
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom'
import LandingPage from './pages/LandingPage'
import DashboardPage from './pages/DashboardPage'
import UploadAudio from './pages/UploadAudio'
import PlanSelection from './pages/PlanSelection'
import Billing from './pages/Billing'
import CustomPlanRequest from './pages/CustomPlanRequest'
import ProfilePage from './pages/ProfilePage'
import Help from './pages/Help'
import TermsAndConditions from './pages/TermsAndConditions'
import PrivacyPolicy from './pages/PrivacyPolicy'
import Login from './pages/Login'
import Register from './pages/Register'
import Header from './components/Header'
import Footer from './components/Footer'
import VerifyEmail from './pages/VerifyEmail'
import VerifyPhone from './pages/VerifyPhone'
import ProtectedRoute from './components/ProtectedRoute'
import useAuth from './hooks/useAuth'
import GoogleCallback from './pages/GoogleCallback'
import RecordingPage from './pages/RecordingPage'
import AdminLoginPage from './admin/AdminLogin'
import AdminDashboardPage from './admin/AdminDashboardPage'
import PlansManagementPage from './admin/PlansManagementPage'
import UsersManagementPage from './admin/UsersManagementPage'
import SubscriptionSuccess from './pages/SubscriptionSuccess'
import SubscriptionCancel from './pages/SubscriptionCancel'

function App() {
  const navigate = useNavigate()
  const location = useLocation()
  const { login } = useAuth()

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const token = params.get('token')

    if (token) {
      const userData = { token }
      login(userData)
      navigate('/dashboard')
    }
  }, [location.search, login, navigate])

  return (
    <>
      <Header />
      <div className='flex flex-col min-h-screen'>
        <main className='flex-grow'>
          <Routes>
            <Route path='/' element={<LandingPage />} />
            <Route
              path='/dashboard'
              element={
                <ProtectedRoute>
                  <DashboardPage />
                </ProtectedRoute>
              }
            />
            <Route
              path='/upload-audio'
              element={
                <ProtectedRoute>
                  <UploadAudio />
                </ProtectedRoute>
              }
            />
            <Route
              path='/plans'
              element={
                <ProtectedRoute>
                  <PlanSelection />
                </ProtectedRoute>
              }
            />
            <Route
              path='/billing'
              element={
                <ProtectedRoute>
                  <Billing />
                </ProtectedRoute>
              }
            />
            <Route
              path='/subscription/success'
              element={
                <ProtectedRoute>
                  <SubscriptionSuccess />
                </ProtectedRoute>
              }
            />
            <Route
              path='/subscription/cancel'
              element={
                <ProtectedRoute>
                  <SubscriptionCancel />
                </ProtectedRoute>
              }
            />
            <Route
              path='/custom-plan-request'
              element={
                <ProtectedRoute>
                  <CustomPlanRequest />
                </ProtectedRoute>
              }
            />
            <Route
              path='/profile'
              element={
                <ProtectedRoute>
                  <ProfilePage />
                </ProtectedRoute>
              }
            />
            <Route path='/help' element={<Help />} />
            <Route path='/terms' element={<TermsAndConditions />} />
            <Route path='/privacy' element={<PrivacyPolicy />} />
            <Route path='/login' element={<Login />} />
            <Route path='/register' element={<Register />} />
            <Route path='/google-callback' element={<GoogleCallback />} />
            <Route path='/verify-email' element={<VerifyEmail />} />
            <Route path='/verify-phone' element={<VerifyPhone />} />
            <Route path='/admin-login' element={<AdminLoginPage />} />
            <Route path='/admin-dashboard' element={<AdminDashboardPage />} />
            <Route
              path='/admin-manage-plans'
              element={<PlansManagementPage />}
            />
            <Route
              path='/admin-manage-users'
              element={<UsersManagementPage />}
            />
            <Route
              path='/recording'
              element={
                <ProtectedRoute>
                  <RecordingPage />
                </ProtectedRoute>
              }
            />
          </Routes>
        </main>
        <Footer />
      </div>
    </>
  )
}

export default App
