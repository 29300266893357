const getInitials = (name) => {
    if (!name) return '';
  
    const nameParts = name.trim().split(' ');
    if (nameParts.length === 1) {
      return nameParts[0].charAt(0).toUpperCase();
    } else {
      return nameParts
        .map(part => part.charAt(0).toUpperCase())
        .join('');
    }
  };
  
  export default getInitials;
  