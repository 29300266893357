import React from 'react';

const SummarySection = ({ title, content }) => (
  <div className="bg-white p-6 rounded-lg shadow-md">
    <h3 className="text-2xl font-bold mb-4">{title}</h3>
    <div className="prose max-w-none">
      {(content || 'No content available').split('\n\n').map((paragraph, index) => (
        <p key={index} className="mb-4">{paragraph}</p>
      ))}
    </div>
  </div>
);

export default SummarySection;
