import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import useAuth from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import ProfileIcon from '../components/ProfileIcon';

const Header = () => {
  const { user, logout } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <header className="bg-[#2c3e50] p-4">
      <div className="container mx-auto flex justify-between items-center">
        <Link to="/">
          <img src="/assets/images/reekap_logo.svg" alt="Reekap Logo" className="h-12" />
        </Link>
        <nav className="hidden md:flex items-center space-x-4">
          <Link to="/plans" className="text-white hover:text-[#38b6ff] font-bold py-2 px-4">Pricing</Link>
          {user ? (
            <div className="flex items-center space-x-4">
              <Link to="/dashboard" className="text-white hover:text-[#38b6ff]">Dashboard</Link>
              <Link to="/plans" className="text-white hover:text-[#38b6ff]">Upgrade</Link>
              <Menu as="div" className="relative">
                <Menu.Button>
                  {user.profileImage ? (
                    <img
                      src={user.profileImage}
                      alt="Profile"
                      className="w-10 h-10 rounded-full border-2 border-white"
                    />
                  ) : (
                    <ProfileIcon name={user.name} />
                  )}
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5">
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to="/profile"
                          className={`${active ? 'bg-gray-100' : ''} block px-4 py-2 text-sm text-gray-700`}
                        >
                          Profile
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={handleLogout}
                          className={`${active ? 'bg-gray-100' : ''} block w-full text-left px-4 py-2 text-sm text-gray-700`}
                        >
                          Logout
                        </button>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          ) : (
            <div className="flex space-x-4">
              <Link to="/login" className="bg-white text-[#2c3e50] font-bold py-2 px-4 rounded hover:bg-gray-100">Login</Link>
              <Link to="/register" className="bg-[#38b6ff] text-white font-bold py-2 px-4 rounded hover:bg-[#2980b9]">Start Free Trial</Link>
            </div>
          )}
        </nav>
        <div className="md:hidden">
          <button onClick={toggleMenu} className="text-white focus:outline-none">
            {isOpen ? <XMarkIcon className="w-6 h-6" /> : <Bars3Icon className="w-6 h-6" />}
          </button>
        </div>
      </div>
      {isOpen && (
        <nav className="md:hidden bg-[#34495e] mt-4 space-y-2">
          <Link to="/plan" className="block text-white hover:text-[#38b6ff] px-2 py-2">Pricing</Link>
          {user ? (
            <>
              <Link to="/dashboard" className="block text-white hover:text-[#38b6ff] px-2 py-2">Dashboard</Link>
              <Link to="/plans" className="block text-white hover:text-[#38b6ff] px-2 py-2">Upgrade</Link>
              <Link to="/profile" className="block text-white hover:text-[#38b6ff] px-2 py-2">Profile</Link>
              <button onClick={handleLogout} className="block w-full text-left text-white hover:text-[#38b6ff] px-2 py-2">Logout</button>
            </>
          ) : (
            <>
              <Link to="/login" className="block bg-white text-[#2c3e50] font-bold py-2 px-4 rounded hover:bg-gray-100 text-center my-2">Login</Link>
              <Link to="/register" className="block bg-[#38b6ff] text-white font-bold py-2 px-4 rounded hover:bg-[#2980b9] text-center my-2">Start Free Trial</Link>
            </>
          )}
        </nav>
      )}
    </header>
  );
};

export default Header;