import React, { useState } from 'react';
import axios from 'axios';
import { Navigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

const VerifyEmail = () => {
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const { user, setUser } = useAuth();

  const API_BASE_URL = process.env.REACT_APP_API_URL;

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;
    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    // Focus on the next input
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      setIsLoading(true);
      const otpCode = otp.join('');
      const response = await axios.post(
        `${API_BASE_URL}/api/auth/verify-email`,
        { userId: user?.id, otp: otpCode }
      );
      if (response.data && response.status === 200) {
        setMessage(response.data.message);
        setUser((prevUser) => ({ ...prevUser, isEmailVerified: true }));
      } else {
        throw new Error('Verification response issue');
      }
      setIsLoading(false);
    } catch (err) {
      console.error('Error during email verification:', err);
      setError(err.response?.data?.error || 'Failed to verify email.');
      setMessage('');
      setIsLoading(false);
    }
  };

  if (user?.id && user?.isEmailVerified) {
    return <Navigate to='/verify-phone' />;
  }

  if (!user?.id && user?.isEmailVerified !== false) {
    return <Navigate to='/register' />;
  }

  return (
    <div className='flex h-[100vh] items-center justify-center bg-gray-100 w-full'>
      <div className='bg-white border shadow-lg rounded-lg flex flex-col items-center w-1/2 p-8'>
        <h1 className='text-2xl font-bold mb-6 text-center'>Verify Email</h1>
        <p className='mb-6 text-center'>Please enter the OTP sent to your email to verify.</p>
        {error && <div className='text-red-600 text-center'>{error}</div>}
        {message && <div className='text-green-600 text-center'>{message}</div>}
        <form onSubmit={onSubmit} className="w-full flex flex-col items-center">
          <div className='flex justify-center mb-8 space-x-2'>
            {otp.map((data, index) => (
              <input
                className='w-10 h-10 text-center border-2 border-blue-500 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500'
                type='text'
                name='otp'
                maxLength='1'
                key={index}
                value={data}
                onChange={(e) => handleChange(e.target, index)}
                onFocus={(e) => e.target.select()}
                required
              />
            ))}
          </div>
          <button
            type='submit'
            className='mt-6 bg-blue-500 text-white py-2 px-4 rounded-md w-auto hover:bg-blue-600 transition-colors duration-200 ease-in-out'
            disabled={isLoading}
          >
            {isLoading ? <div className='loading'></div> : 'Verify Email'}
          </button>
        </form>
      </div>
    </div>
  );
  
};

export default VerifyEmail;
