import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-[#2c3e50] text-white py-4">
      <div className="container mx-auto text-center">
        <p>&copy; 2024 Reekap by RavenZoneInc. All rights reserved.</p>
        <nav className="mt-2">
          <Link to="/privacy" className="text-gray-300 hover:text-[#38b6ff] mx-2">Privacy Policy</Link>
          <Link to="/terms" className="text-gray-300 hover:text-[#38b6ff] mx-2">Terms and Conditions</Link>
        </nav>
      </div>
    </footer>
  );
};

export default Footer;
