import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { convertSecondsToMinutes } from '../utils/secondsToMinutes'

const AdminDashboardPage = () => {
  const [metrics, setMetrics] = useState({
    totalUsers: 0,
    paidUsers: 0,
    totalMinutes: 0,
    noOfTranscriptions: 0,
    revenue: 0,
  })
  const [userName, setUserName] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        // Fetch user data
        const userRes = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/admin/profile/me`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        )
        setUserName(userRes.data.data.name)

        // Fetch metrics
        const resp = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/admin/metrics`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        )

        const data = await resp.data.data
        setMetrics({
          totalUsers: data.total_users,
          paidUsers: data.paid_users,
          totalMinutes: data.total_usage_seconds,
          noOfTranscriptions: data.no_of_transcriptions,
          revenue: data.total_revenue,
        })
      } catch (err) {
        console.error('Error fetching dashboard data:', err)
      }
    }

    fetchDashboardData()
  }, [])

  const handleNavigation = (path) => {
    navigate(path)
  }

  return (
    <div className='flex flex-col min-h-screen bg-gray-100 p-8'>
      <h1 className='text-3xl font-bold mb-4'>Welcome, {userName}!</h1>
      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8'>
        <div
          className='bg-white p-6 rounded-lg shadow-md cursor-pointer hover:bg-gray-100'
          onClick={() => handleNavigation('/admin/metrics')}
        >
          <h2 className='text-xl font-semibold mb-2'>Metrics Overview</h2>
          <p>Total Users: {metrics.totalUsers}</p>
          <p>Paid Users: {metrics.paidUsers}</p>
          <p>Total Minutes: {convertSecondsToMinutes(metrics.totalMinutes)}</p>
          <p>Total Transcriptions: {metrics.noOfTranscriptions}</p>
          <p>Revenue: ${metrics.revenue}</p>
        </div>
        <div
          className='bg-white p-6 rounded-lg shadow-md cursor-pointer hover:bg-gray-100'
          onClick={() => handleNavigation('/admin-manage-plans')}
        >
          <h2 className='text-xl font-semibold mb-2'>Plan Management</h2>
          <p>Manage and update plans here.</p>
        </div>
        <div
          className='bg-white p-6 rounded-lg shadow-md cursor-pointer hover:bg-gray-100'
          onClick={() => handleNavigation('/admin-manage-users')}
        >
          <h2 className='text-xl font-semibold mb-2'>User Management</h2>
          <p>Manage and view users here.</p>
        </div>
        <div
          className='bg-white p-6 rounded-lg shadow-md cursor-pointer hover:bg-gray-100'
          onClick={() => handleNavigation('/admin/support')}
        >
          <h2 className='text-xl font-semibold mb-2'>Support Management</h2>
          <p>View and handle support requests here.</p>
        </div>
      </div>
    </div>
  )
}

export default AdminDashboardPage
