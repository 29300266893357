export function convertSecondsToMinutes(seconds) {
  return Math.ceil(seconds / 60)
}

export const getAudioDuration = (file) => {
  return new Promise((resolve, reject) => {
    const audioContext = new (window.AudioContext ||
      window.webkitAudioContext)()

    const reader = new FileReader()
    reader.onload = () => {
      audioContext.decodeAudioData(
        reader.result,
        (buffer) => {
          resolve(buffer.duration)
        },
        (error) => {
          reject(error)
        }
      )
    }

    reader.onerror = (error) => {
      reject(error)
    }

    reader.readAsArrayBuffer(file)
  })
}
