import axios from 'axios'
import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import useAuth from '../hooks/useAuth'

const GoogleCallback = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { setUser } = useAuth()

  useEffect(() => {
    const API_BASE_URL = process.env.REACT_APP_API_URL
    const googlAuthCallbackHandler = async (token) => {
      try {
        const response = await axios(`${API_BASE_URL}/api/auth/user`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const userData = response.data
        setUser({ ...userData.user, token })
        if (!userData.user.isPhoneVerified) {
          navigate('/verify-phone')
        } else {
          localStorage.setItem('token', token)
          navigate('/dashboard')
        }
      } catch (error) {
        navigate('/login')
      }
    }

    const params = new URLSearchParams(location.search)
    const token = params.get('tokens')

    if (!token) {
      navigate('/login')
    } else {
      googlAuthCallbackHandler(token)
    }
  }, [location, navigate, setUser])

  return (
    <div className='flex justify-center items-center h-screen w-screen'>
      <div className='loading'></div>
    </div>
  )
}

export default GoogleCallback
