import React from 'react';

const LoadingSpinner = () => (
  <div className="flex flex-col items-center justify-center space-y-4">
    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
    <p className="text-lg text-center max-w-md">
      Your summary is currently ongoing. It may take between 1-5 mins depending on the length of your meeting. 
      Take this time to sip a cup of coffee and think about your next vacation! Where would you love to go?
    </p>
  </div>
);

export default LoadingSpinner;