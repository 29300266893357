import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaMicrophone, FaRegFileAudio } from 'react-icons/fa';

const QuickActions = () => {
  const navigate = useNavigate();

  const handleNavigateToRecording = () => {
    navigate('/recording');
  };

  const handleNavigateToUpload = () => {
    navigate('/upload-audio');
  };

  return (
    <section className="mb-8">
      <h2 className="text-2xl font-semibold mb-4">Quick Actions</h2>
      <div className="flex flex-col lg:flex-row gap-4">
        <button
          onClick={handleNavigateToRecording}
          className="flex-1 bg-blue-600 text-white py-8 px-12 rounded-lg shadow-md hover:bg-blue-700 transition flex items-center justify-center text-center w-full lg:w-auto"
        >
          <FaMicrophone className="text-6xl mb-2" />
          <span className="text-xl">Record Meeting</span>
        </button>
        <button
          onClick={handleNavigateToUpload}
          className="flex-1 bg-green-600 text-white py-8 px-12 rounded-lg shadow-md hover:bg-green-700 transition flex items-center justify-center text-center w-full lg:w-auto"
        >
          <FaRegFileAudio className="text-6xl mb-2" />
          <span className="text-xl">Upload Audio</span>
        </button>
      </div>
    </section>
  );
};

export default QuickActions;
