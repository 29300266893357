import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaRegFileAudio, FaBell, FaClipboardList } from 'react-icons/fa';

const Sidebar = ({ isOpen, toggleSidebar }) => {

  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path;
  };


  return (
    <aside
      className={`bg-[#2c3e50] text-white w-64 py-8 fixed inset-y-0 left-0 transform ${
        isOpen ? 'translate-x-0' : '-translate-x-full'
      } transition-transform duration-300 ease-in-out md:relative md:translate-x-0`}
    >
      <div className="px-6">
        <nav>
          <ul>
            <li className="mb-4">
              <Link to="/dashboard" className={`flex items-center p-2 rounded ${isActive('/dashboard') ? 'bg-[#38b6ff] text-white' : 'hover:bg-[#38b6ff] hover:text-white'}`}>
                <FaClipboardList className="mr-2" />
                Dashboard
              </Link>
            </li>
            <li className="mb-4">
            <Link 
                to="/notifications" 
                className={`flex items-center p-2 rounded ${isActive('/notifications') ? 'bg-[#38b6ff] text-white' : 'hover:bg-[#38b6ff] hover:text-white'}`}
              >
                <FaBell className="mr-2" />
                Notifications
              </Link>
            </li>
            <li className="mb-4">
            <Link 
                to="/support" 
                className={`flex items-center p-2 rounded ${isActive('/support') ? 'bg-[#38b6ff] text-white' : 'hover:bg-[#38b6ff] hover:text-white'}`}
              >
                <FaRegFileAudio className="mr-2" />
                Support/Help
              </Link>
            </li>
          
          </ul>
        </nav>
      </div>
      <button
        className="absolute top-4 right-4 md:hidden text-white focus:outline-none"
        onClick={toggleSidebar}
      >
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
        </svg>
      </button>
    </aside>
  );
};

export default Sidebar;
