import React, { useState, useEffect } from 'react'
import axios from 'axios'

import SingleUserDetails from '../components/SingleUserDetails'
import Modal from '../components/Modal'

const UserManagementPage = () => {
  const [users, setUsers] = useState([])
  const [searchInput, setSearchInput] = useState('')
  const [selectedUser, setSelectedUser] = useState(null)

  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/admin/users?query=${searchInput}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        )
        setUsers(response.data.data.users)
      } catch (error) {
        console.error('Error fetching users:', error)
      }
    }

    fetchUsers()
  }, [searchInput])

  const handleDeleteUser = async (userId) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
        await axios.delete(
          `${process.env.REACT_APP_API_URL}/api/admin/users/delete/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        )
        // Fetch users again to reflect changes
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/admin/users`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        )
        setUsers(response.data.data.users)
      } catch (error) {
        console.error('Error deleting user:', error)
      }
    }
  }

  return (
    <div className='p-6'>
      <h1 className='text-2xl font-bold mb-6'>User Management</h1>

      <div className='mb-6'>
        <input
          type='text'
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          placeholder='Search users...'
          className='mb-4 p-2 border rounded'
        />
      </div>

      <div className='grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-4'>
        {users.map((user) => (
          <div key={user._id} className='bg-white p-4 border rounded shadow-md'>
            <h2 className='text-xl font-semibold mb-2'>{user.name}</h2>
            <p>Email: {user.email}</p>
            <p>Phone: {user.phone || 'N/A'}</p>
            <p>Email Verified: {user.isEmailVerified ? 'Yes' : 'No'}</p>
            <p>Phone Verified: {user.isPhoneVerified ? 'Yes' : 'No'}</p>
            <p>Plan: {user.subscription?.planId?.name || 'N/A'}</p>
            <button
              onClick={() => {
                setSelectedUser(user)
                setIsModalOpen(true)
              }}
              className='bg-blue-500 text-white px-4 py-2 rounded mt-4 hover:bg-blue-600'
            >
              View Details
            </button>
            <button
              onClick={() => handleDeleteUser(user._id)}
              className='bg-red-500 text-white px-4 py-2 rounded mt-2 hover:bg-red-600'
            >
              Delete
            </button>
          </div>
        ))}
      </div>

      {isModalOpen && (
        <Modal isOpen={isModalOpen}>
          <SingleUserDetails
            setUsers={setUsers}
            selectedUser={selectedUser}
            setIsModalOpen={setIsModalOpen}
          />
        </Modal>
      )}
    </div>
  )
}

export default UserManagementPage
