import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Sidebar from '../components/Sidebar';
import UsageStatistics from '../components/UsageStatistics';
import QuickActions from '../components/QuickActions';
import NotificationsPanel from '../components/NotificationsPanel';

const DashboardPage = () => {
  const [usageStats] = useState({
    recordings: 40,
    transcriptions: 60,
    summaries: 80,
    minutes: 50,
    maxRecordings: 100,
    maxTranscriptions: 100,
    maxSummaries: 100,
    maxMinutes: 100,
  });

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [userName, setUserName] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/me`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setUserName(res.data.name);
      } catch (err) {
        console.error('Error fetching user data:', err);
      }
    };

    fetchUserData();
  }, []);

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <div className="flex flex-1">
        <Sidebar isOpen={sidebarOpen} toggleSidebar={() => setSidebarOpen(!sidebarOpen)} />
        <div className={`flex-1 p-4 md:p-8 transition-transform duration-300 ${sidebarOpen ? 'transform md:translate-x-0' : 'md:ml-12'} min-w-0`}>
          <section className="mb-8">
            <h1 className="text-3xl font-bold mb-2 text-[#2c3e50]">Hi, {userName}!</h1>
            <p className="text-gray-600">Here's what's happening with your account today:</p>
          </section>
          <UsageStatistics usageStats={usageStats} />
          <QuickActions navigate={navigate} />
          <NotificationsPanel />
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
