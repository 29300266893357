import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import useAuth from '../hooks/useAuth'

const AdminLogin = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const navigate = useNavigate()
  const { login } = useAuth()

  const API_BASE_URL = process.env.REACT_APP_API_URL

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!email || !password) {
      setErrorMessage('Please fill in both fields.')
      return
    }

    try {
      setIsLoading(true)
      const response = await axios.post(
        `${API_BASE_URL}/api/admin/auth/login`,
        {
          email,
          password,
        }
      )

      // Log the received token for debugging
      console.log('Received token:', response.data.data.token)

      // Store the token in local storage
      localStorage.setItem('token', response.data.data.token)

      // Redirect to the admin dashboard
      login({ ...response.data.data, isAdmin: true })
      navigate('/admin-dashboard')
    } catch (error) {
      const errorMsg =
        error.response?.data?.message ||
        'An error occurred. Please try again later.'
      if (error.response && error.response.status === 401) {
        setErrorMessage(errorMsg || 'Invalid credentials.')
      } else {
        setErrorMessage(errorMsg)
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className='h-[100vh] flex items-center justify-center px-5 lg:px-0'>
      <div className='max-w-screen-xl bg-white border shadow sm:rounded-lg flex justify-center flex-1'>
        <div className='flex-1 bg-blue-900 text-center hidden md:flex'>
          <div
            className='m-12 xl:m-16 w-full bg-contain bg-center bg-no-repeat'
            style={{
              backgroundImage: `url(https://www.tailwindtap.com/assets/common/marketing.svg)`,
            }}
          ></div>
        </div>
        <div className='lg:w-1/2 xl:w-5/12 p-6 sm:p-12'>
          <div className='flex flex-col items-center'>
            <div className='text-center'>
              <h1 className='text-2xl xl:text-4xl font-extrabold text-blue-900 mb-2'>
                Admin Sign In
              </h1>
              <p className='text-[12px] text-gray-500 mb-6'>
                Enter your email and password to sign in.
              </p>
            </div>
            {errorMessage && (
              <div className='bg-red-100 text-red-700 border border-red-400 px-4 py-3 rounded mb-4'>
                {errorMessage}
              </div>
            )}
            <form className='w-full flex-1 mt-8' onSubmit={handleSubmit}>
              <div className='mx-auto max-w-xs flex flex-col gap-4'>
                <input
                  className='w-full px-5 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white'
                  type='email'
                  placeholder='Enter your email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <input
                  className='w-full px-5 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white'
                  type='password'
                  placeholder='Enter your password'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <button
                  className='mt-5 tracking-wide font-semibold bg-blue-900 text-gray-100 w-full py-4 rounded-lg hover:bg-indigo-700 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none'
                  disabled={isLoading}
                >
                  <span className='ml-3'>
                    {isLoading ? <div className='loading'></div> : 'Sign In'}
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminLogin
