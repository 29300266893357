import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Privacy Policy</h1>
      <p>Our privacy policy details how we handle your data...</p>
    </div>
  );
};

export default PrivacyPolicy;
