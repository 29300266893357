import React, { createContext, useState } from 'react'

const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)

  const login = (userData) => {
    localStorage.setItem('token', userData.token)
    console.log(userData.token)

    localStorage.setItem(
      'userData',
      JSON.stringify(userData?.user || userData?.admin)
    )
    setUser(userData.user)
  }

  const logout = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('userData')
    setUser(null)
  }

  return (
    <AuthContext.Provider value={{ user, setUser, login, logout }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext
