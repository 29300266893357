import React from 'react'
import { convertSecondsToMinutes } from '../utils/secondsToMinutes'
import useAuth from '../hooks/useAuth'

const PlanCard = ({ plan, onSelect }) => {
  const { user } = useAuth()

  const userPlanId = user.subscription.planId

  const isUsersPlan = userPlanId === plan._id

  const proceedToPayment = () => {
    if (isUsersPlan) return
    onSelect(plan)
  }

  return (
    <div className='plan-card p-4 border rounded'>
      <h2 className='text-xl font-bold mb-2'>{plan.name}</h2>
      <p>{plan.description}</p>
      <p>Recording Minutes: {convertSecondsToMinutes(plan.allowedLimit)}</p>
      <p>Price: ${plan.price}</p>
      <button
        onClick={proceedToPayment}
        className={`${
          isUsersPlan ? 'bg-green-500' : 'bg-blue-500 hover:bg-blue-700'
        } text-white font-bold py-2 px-4 rounded mt-4`}
      >
        {isUsersPlan ? 'Current Plan' : 'Select'}
      </button>
    </div>
  )
}

export default PlanCard
