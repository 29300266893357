import React, { useState } from 'react';
import axios from 'axios';
import { Navigate, useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

const VerifyPhone = () => {
  const [phone, setPhone] = useState('');
  const [countryCode, setCountryCode] = useState('+1'); // Default country code
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [showOtpSection, setShowOtpSection] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false); // State for checkbox
  const { user, login } = useAuth();
  const navigate = useNavigate();

  const API_BASE_URL = process.env.REACT_APP_API_URL;

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;
    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    // Focus on the next input
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');

    if (!isAgreed) {
      setError('You must agree to receive text messages.');
      return;
    }

    console.log('Sending phone verification code to:', countryCode + phone);
    console.log('User token:', user?.token);

    try {
      setIsLoading(true);
      const response = await axios.post(
        `${API_BASE_URL}/api/auth/verify-phone`,
        { phone: countryCode + phone, userId: user?.id }
      );
      console.log('Response from server:', response);
      if (response && response.data) {
        setMessage(response.data.message);
        setShowOtpSection(true);
      } else {
        setError('Unexpected response from the server.');
      }
      setIsLoading(false);
    } catch (err) {
      console.error('Error sending verification code:', err);
      setError(err.response?.data?.error || 'Failed to send verification code.');
      setIsLoading(false);
    }
  };

  const onOtpSubmit = async (e) => {
    e.preventDefault();

    console.log('Verifying OTP:', otp.join(''));

    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/auth/verify-phone-otp`,
        { otp: otp.join(''), userId: user?.id } // Ensure the key is 'otp' to match the backend handler
      );
      console.log('Response from server:', response);
      if (response && response.data) {
        setMessage(response.data.message);
        login(response.data);
        navigate('/dashboard');
      } else {
        setError('Unexpected response from the server.');
      }
    } catch (err) {
      console.error('Error verifying OTP:', err);
      setError(err.response?.data?.error || 'Failed to verify phone number.');
      setMessage('');
    }
  };

  if (!user?.id) {
    return <Navigate to='/register' />;
  }

  // Redirect to email verification if email is not verified
  if (user?.id && !user?.isEmailVerified) {
    return <Navigate to='/verify-email' />;
  }
  if (user?.id && user?.isPhoneVerified) {
    return <Navigate to='/dashboard' />;
  }

  return (
    <div className="flex h-[100vh] items-center justify-center bg-gray-100 w-full">
      <div className="bg-white border shadow-lg rounded-lg flex flex-col items-center w-1/2 p-8">
        <div className="p-6 sm:p-8">
          <h1 className="text-2xl sm:text-3xl font-bold text-center text-gray-800 mb-4">
            Verify Phone
          </h1>
          <p className="text-center text-gray-600 mb-6">
            Enter your phone number to receive a verification code.
          </p>
          
          {error && (
            <div className="bg-red-100 text-red-700 p-3 rounded-md mb-4">
              {error}
            </div>
          )}
          {message && (
            <div className="bg-green-100 text-green-700 p-3 rounded-md mb-4 text-center">
              {message}
            </div>
          )}
  
          <form onSubmit={onSubmit} className="space-y-6 mb-8">
            <div className="flex justify-center space-x-2">
              <select
                className="w-1/4 p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                value={countryCode}
                onChange={(e) => setCountryCode(e.target.value)}
                required
              >
                <option value="+1">+1</option>
                <option value="+44">+44</option>
                <option value="+91">+91</option>
                <option value="+234">+234</option>
              </select>
              <input
                className="w-3/4 p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                type="tel"
                placeholder="Enter your phone number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
              />
            </div>
            <div className="flex items-center">
              <input
                type="checkbox"
                id="agree"
                className="mr-2"
                checked={isAgreed}
                onChange={(e) => setIsAgreed(e.target.checked)}
                required
              />
              <label htmlFor="agree" className="text-gray-600">
                I agree to receive text messages from Reekap by RavenZone.
              </label>
            </div>
            <div className="flex justify-center">
              <button
                className="px-6 bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? (
                  <div className="flex justify-center items-center">
                    <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white"></div>
                  </div>
                ) : (
                  "Send Verification Code"
                )}
              </button>
            </div>
          </form>
  
          {showOtpSection && (
            <div className="mt-8">
              <p className="text-center text-gray-600 mb-4">
                Enter the OTP sent to your phone.
              </p>
              <form onSubmit={onOtpSubmit} className="space-y-6">
                <div className="flex justify-center space-x-2">
                  {otp.map((data, index) => (
                    <input
                      key={index}
                      className="w-10 h-10 text-center text-xl font-semibold border-2 border-blue-500 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      type="text"
                      name="otp"
                      maxLength="1"
                      value={data}
                      onChange={(e) => handleChange(e.target, index)}
                      onFocus={(e) => e.target.select()}
                      required
                    />
                  ))}
                </div>
                <div className="flex justify-center">
                  <button
                    className="px-6 bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <div className="flex justify-center items-center">
                        <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white"></div>
                      </div>
                    ) : (
                      "Verify OTP"
                    )}
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VerifyPhone;
