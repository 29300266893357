import React from 'react'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom'
import Lottie from 'lottie-react'

import errorLottie from '../assets/lottie/error-lottie.json'

const SubscriptionCancel = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const redirect = searchParams.get('redirect')

  const proceedToDashboard = () => {
    navigate('/dashboard')
  }

  if (!redirect) {
    return <Navigate to='/dashboard' />
  }

  return (
    <main className='w-screen h-screen mt-2 flex items-center justify-center'>
      <div className='w-[90%] max-w-2xl mx-auto flex flex-col gap-4 items-center justify-center'>
        <div className='w-40'>
          <Lottie animationData={errorLottie} loop={false} />
        </div>
        <h4 className='text-lg sm:text-3xl'>Payment Canceled</h4>
        <p className='text-lg text-center'>
          It seems you've canceled the payment process. No charges were made. If
          you'd like to complete your purchase, you can try again at any time.
        </p>

        <button
          className='mt-5 tracking-wide font-semibold bg-blue-900 text-gray-100 py-4 px-4 rounded-lg hover:bg-indigo-700 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none'
          onClick={proceedToDashboard}
        >
          Back To Dashboard
        </button>
      </div>
    </main>
  )
}

export default SubscriptionCancel
