import React from 'react';
import PropTypes from 'prop-types';
import getInitials from '../utils/getInitials';

const ProfileIcon = ({ name }) => {
  const initials = getInitials(name);

  return (
    <div className="flex items-center justify-center bg-white text-blue-500 font-bold rounded-full w-10 h-10">
      {initials}
    </div>
  );
};

ProfileIcon.propTypes = {
  name: PropTypes.string.isRequired,
};

export default ProfileIcon;
