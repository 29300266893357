import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { FaTimes } from 'react-icons/fa'
import { convertSecondsToMinutes } from '../utils/secondsToMinutes'

const SingleUserDetails = ({ selectedUser, setUsers, setIsModalOpen }) => {
  const [user, setUser] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)
  const [topUpAmount, setTopUpAmount] = useState('')

  const fetchUser = async () => {
    try {
      setIsLoading(true)
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/admin/users/single/${selectedUser._id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      )
      setUser(response.data.data.user)
      setIsLoading(false)
    } catch (error) {
      console.error('Error fetching users:', error)
      setError(true)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser._id])

  const handleTopUp = async (userId) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/admin/users/top-up`,
        { userId, amount: topUpAmount },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      )
      setTopUpAmount('')

      fetchUser()
      // Fetch users again to reflect changes
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/admin/users`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      )
      setUsers(response.data.data.users)
    } catch (error) {
      console.error('Error topping up user:', error)
    }
  }

  const handleActivate = async (userId, type) => {
    if (type === 'email' && user?.isEmailVerified) return
    if (type === 'phone' && user?.isPhoneVerified) return
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/admin/users/activate`,
        { userId, type },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      )
      // Fetch users again to reflect changes
      fetchUser()
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/admin/users`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      )
      setUsers(response.data.data.users)
    } catch (error) {
      console.error('Error activating user:', error)
    }
  }

  if (isLoading) {
    return (
      <div className='py-40'>
        <div className='loading'></div>
      </div>
    )
  }

  if (error) {
    return (
      <div className='flex flex-col gap-4 items-center justify-center text-center my-10'>
        <h4> Error While Fetching User Info</h4>
        <button
          className='bg-red-500 text-white px-2.5 py-2 rounded-md'
          onClick={() => setIsModalOpen(false)}
        >
          Cancel
        </button>
      </div>
    )
  }

  return (
    <div className='mt-6 p-4'>
      <div className='flex justify-between items-center  mb-4'>
        <h2 className='text-xl font-semibold'>User Details</h2>
        <button
          s
          className='text-red-500'
          onClick={() => {
            setIsModalOpen(false)
          }}
        >
          <FaTimes />
        </button>
      </div>
      <p>Name: {user?.name}</p>
      <p>Email: {user?.email}</p>
      <p>Phone: {user?.phone || 'N/A'}</p>
      <p>Email Verified: {user?.isEmailVerified ? 'Yes' : 'No'}</p>
      <p>Phone Verified: {user?.isPhoneVerified ? 'Yes' : 'No'}</p>
      <p>Plan: {user?.subscription?.planId?.name || 'N/A'}</p>
      <p>
        Plan Limit:{' '}
        {convertSecondsToMinutes(user?.subscription?.planId?.allowedLimit) +
          'minutes' || 'N/A'}
      </p>
      <p>
        Bonus Limit:{' '}
        {convertSecondsToMinutes(user?.usage?.bonusLimit) + 'minutes' || 'N/A'}
      </p>

      <p>
        Remaining Minutes:{' '}
        {user.subscription?.planId
          ? `${convertSecondsToMinutes(
              user.subscription?.planId?.allowedLimit +
                user.usage.bonusLimit -
                (user?.usage?.recordingSeconds + user?.usage?.uploadSeconds)
            )}minutes`
          : 'N/A'}
      </p>
      <p>
        All Time Total Usage: {convertSecondsToMinutes(user?.usage?.totalUsage)}
        minutes
      </p>
      <div className='mt-4'>
        <input
          type='number'
          placeholder='Top-Up Minutes'
          value={topUpAmount}
          onChange={(e) => setTopUpAmount(e.target.value)}
          className='p-2 border rounded mr-2'
        />
        <button
          onClick={() => handleTopUp(user._id)}
          className='bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600'
        >
          Top-Up
        </button>
      </div>
      <div className='mt-4 flex gap-4'>
        <button
          onClick={() => handleActivate(user._id, 'email')}
          disabled={user?.isEmailVerified}
          className='bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-600 disabled:bg-yellow-400 disabled:hover:bg-yellow-400 disabled:cursor-not-allowed'
        >
          Verify Email
        </button>
        <button
          onClick={() => handleActivate(user._id, 'phone')}
          disabled={user?.isPhoneVerified}
          className='bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-600 disabled:bg-yellow-400 disabled:hover:bg-yellow-400 disabled:cursor-not-allowed'
        >
          Verify Phone
        </button>
      </div>
    </div>
  )
}

export default SingleUserDetails
