// src/pages/ProfilePage.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import useAuth from '../hooks/useAuth';

const ProfilePage = () => {
  const { setUser } = useAuth();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    plan: '',
    password: '',
  });
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/me`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        const { name, email, phone, subscription } = response.data;
        setFormData({
          name,
          email,
          phone,
          plan: subscription.planId.name,
          password: '',
        });
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };

    fetchUserProfile();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSaveChanges = async () => {
    setSuccessMessage('');
    setErrorMessage('');

    const updatedData = {
      name: formData.name,
      password: formData.password,
    };

    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/user/me`, updatedData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setUser(response.data);
      setSuccessMessage('Profile updated successfully!');
    } catch (error) {
      console.error('Error updating profile:', error);
      setErrorMessage('Failed to update profile.');
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg mt-8">
      <h2 className="text-2xl font-bold mb-6">Profile</h2>
      {successMessage && <div className="bg-blue-100 text-blue-700 p-3 rounded-md mb-4">{successMessage}</div>}
      {errorMessage && <div className="bg-red-100 text-red-700 p-3 rounded-md mb-4">{errorMessage}</div>}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="flex flex-col">
          <label className="mb-2 font-semibold text-gray-700" htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            className="px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div className="flex flex-col">
          <label className="mb-2 font-semibold text-gray-700" htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            readOnly
            className="px-4 py-2 border rounded-lg bg-gray-100"
          />
        </div>
        <div className="flex flex-col">
          <label className="mb-2 font-semibold text-gray-700" htmlFor="phone">Phone</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
            readOnly
            className="px-4 py-2 border rounded-lg bg-gray-100"
          />
        </div>
        <div className="flex flex-col">
          <label className="mb-2 font-semibold text-gray-700" htmlFor="plan">Plan</label>
          <input
            type="text"
            id="plan"
            name="plan"
            value={formData.plan}
            readOnly
            className="px-4 py-2 border rounded-lg bg-gray-100"
          />
        </div>
        <div className="flex flex-col">
          <label className="mb-2 font-semibold text-gray-700" htmlFor="password">New Password</label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleInputChange}
            className="px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
      </div>
      <button
        onClick={handleSaveChanges}
        className="mt-6 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
      >
        Save Changes
      </button>
    </div>
  );
};

export default ProfilePage;
