import React from 'react'

const Modal = ({ isOpen, children }) => {
  if (!isOpen) return null

  return (
    <div className='fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center overflow-y-auto h-full w-full'>
      <div className='mx-auto p-5 w-[500px] shadow-lg rounded-[10px] py-[15px] px-4 bg-gray-200 shadow-[0px,5px,31.6px,0px,#00000040] flex flex-col gap-3'>
        {children}
      </div>
    </div>
  )
}

export default Modal
