import React, { useState, useRef, useEffect, useCallback } from 'react'
import axios from 'axios'
import SummarySection from '../components/SummarySection'
import LoadingSpinner from '../components/LoadingSpinner'
import {
  AiOutlineFileWord,
  AiOutlineFilePdf,
  AiOutlineMail,
  AiOutlineCopy,
} from 'react-icons/ai'
import { jsPDF } from 'jspdf'
import { Document, Packer, Paragraph } from 'docx'
import { saveAs } from 'file-saver'

import useAuth from '../hooks/useAuth'
import Modal from '../components/Modal'
import { Link } from 'react-router-dom'

const RecordingPage = () => {
  const { user } = useAuth()

  const [isRecording, setIsRecording] = useState(false)
  const [isPaused, setIsPaused] = useState(false)
  const [recordingTime, setRecordingTime] = useState(0)
  const [recordedBlob, setRecordedBlob] = useState(null)
  const [results, setResults] = useState({
    general: '',
    minutes: '',
    simple: '',
  })
  const [showActions, setShowActions] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isLimitLoading, setIsLimitLoading] = useState(false)
  const [userLimit, setUserLimit] = useState(0) // User's allowed time in seconds
  const [usedSeconds, setUsedSeconds] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [recordExceeded, setRecordExceeded] = useState(false)
  const [canGenerateSummaries, setCanGenerateSummaries] = useState(false)
  const mediaRecorderRef = useRef(null)
  const chunksRef = useRef([])
  const timerRef = useRef(null)
  const [summariesGenerated, setSummariesGenerated] = useState(false)

  const limitReached = usedSeconds >= userLimit

  const fetchLimits = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/usage`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        }
      )

      const data = await response.data
      const { currentPeriodUsage, allowedLimit } = data.data

      setUserLimit(allowedLimit)
      setUsedSeconds(Number(currentPeriodUsage))
    } catch (error) {
      console.error('Error fetching usage limits:', error)
    }
  }, [])

  useEffect(() => {
    setIsLimitLoading(true)
    fetchLimits()
    setIsLimitLoading(false)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (isRecording) {
      timerRef.current = setInterval(() => {
        setRecordingTime((prevTime) => {
          const newTime = prevTime + 1
          if (usedSeconds + newTime >= userLimit) {
            setRecordExceeded(true)
            handleRecordStop(true)
          }
          return newTime
        })
      }, 1000)
    } else {
      clearInterval(timerRef.current)
    }
    return () => clearInterval(timerRef.current)
    // eslint-disable-next-line
  }, [isRecording, userLimit, usedSeconds])

  const handleCancel = () => {
    setShowModal(false)
    setShowActions(false)
  }

  const handleRecordStart = async () => {
    try {
      await fetchLimits()

      if (limitReached) {
        return
      }
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      })
      const mediaRecorder = new MediaRecorder(stream)
      mediaRecorderRef.current = mediaRecorder

      mediaRecorder.ondataavailable = (event) => {
        chunksRef.current.push(event.data)
      }

      mediaRecorder.start()
      setIsRecording(true)
      setRecordingTime(0)

      console.log('Recording started')
    } catch (error) {
      alert('Failed to start recording. Please try again.')
    }
  }

  const handleRecordPause = () => {
    mediaRecorderRef.current.pause()
    setIsPaused(true)
    clearInterval(timerRef.current)
    console.log('Recording paused')
  }

  const handleRecordResume = () => {
    mediaRecorderRef.current.resume()
    setIsPaused(false)
    timerRef.current = setInterval(() => {
      setRecordingTime((prevTime) => prevTime + 1)
    }, 1000)
    console.log('Recording resumed')
  }

  const logUsage = async (duration) => {
    setIsLoading(true)
    try {
      const token = user.token

      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/usage/log`,
        {
          type: 'recording',
          seconds: duration,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      setUsedSeconds((prev) => prev + duration)
    } catch (error) {
      console.error('Error logging recording usage:', error)
      alert('Failed to log recording usage. Please try again.')
    }
    setIsLoading(false)
  }

  const saveRecording = async (blob) => {
    const timestamp = new Date().toISOString().replace(/[:.]/g, '-')
    const defaultFileName = `recording_${timestamp}.wav`

    try {
      if ('showSaveFilePicker' in window) {
        const fileHandle = await window.showSaveFilePicker({
          suggestedName: defaultFileName,
          types: [
            {
              description: 'WAV Audio File',
              accept: { 'audio/wav': ['.wav'] },
            },
          ],
        })

        const writable = await fileHandle.createWritable()
        await writable.write(blob)

        await writable.close()
        console.log('Recording saved using File System Access API')
      } else {
        const url = URL.createObjectURL(recordedBlob)
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = defaultFileName
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)
        console.log('Recording download prompt shown (fallback method)')
      }
    } catch (err) {
      if (err.name === 'AbortError') {
        console.warn('User aborted the save operation')
      } else {
        console.error('Error saving the file:', err)
        alert('An error occurred while saving the file. Please try again.')
      }
    }
  }

  const saveAndGenerateTranscript = async (isSaving) => {
    if (isSaving) {
      await saveRecording(recordedBlob)
    } // Directly prompt save for manual stop
    setShowModal(false)
    await logUsage(recordingTime)

    const formData = new FormData()
    formData.append('file', recordedBlob)
    setIsLoading(true)

    try {
      const MAX_CHUNK_SIZE = 24 * 1024 * 1024 // 24MB

      const arrayBuffer = await recordedBlob.arrayBuffer()
      const buffer = new Uint8Array(arrayBuffer)
      const chunks = []

      const splitAudioBySize = (buffer) => {
        for (let i = 0; i < buffer.length; i += MAX_CHUNK_SIZE) {
          const chunk = buffer.slice(i, i + MAX_CHUNK_SIZE)
          chunks.push(chunk)
        }
        return chunks
      }

      const audioChunks = splitAudioBySize(buffer)
      const transcriptions = []

      for (const chunk of audioChunks) {
        const chunkBlob = new Blob([chunk], { type: 'audio/wav' })
        const chunkFormData = new FormData()
        chunkFormData.append('file', chunkBlob)

        const chunkResponse = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/audio/transcribe_and_summarize_all`,
          chunkFormData,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
          }
        )
        transcriptions.push(chunkResponse.data)
      }

      const combinedTranscriptions = transcriptions.reduce(
        (acc, transcription) => {
          acc.general += transcription.generalSummary + ' '
          acc.minutes += transcription.meetingMinutes + ' '
          acc.simple += transcription.simpleSummary + ' '
          return acc
        },
        { general: '', minutes: '', simple: '' }
      )

      setResults({
        general: combinedTranscriptions.general,
        minutes: combinedTranscriptions.minutes,
        simple: combinedTranscriptions.simple,
      })
      setShowActions(false)
      setSummariesGenerated(true)
      setCanGenerateSummaries(true)
      alert('Summaries generated successfully.')
    } catch (error) {
      console.error('Error generating summaries:', error)
      alert('Failed to generate summaries. Please try again.')
    } finally {
      setIsLoading(false)
    }
  }

  const handleRecordStop = async (isExceeded = true) => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop()
    }
    setIsRecording(false)
    setIsPaused(false)

    mediaRecorderRef.current.onstop = async () => {
      clearInterval(timerRef.current)
      const blob = new Blob(chunksRef.current, { type: 'audio/wav' })
      chunksRef.current = []

      setRecordedBlob(blob)
      setShowActions(true)

      // Stop all tracks to release the microphone
      if (mediaRecorderRef.current.stream) {
        mediaRecorderRef.current.stream
          .getTracks()
          .forEach((track) => track.stop())
      }

      setShowModal(true)
    }
  }

  const handleGenerateSummaries = async () => {
    if (!recordedBlob || !canGenerateSummaries) {
      alert('Please record a meeting first.')
      return
    }

    const formData = new FormData()
    formData.append('file', recordedBlob)
    setIsLoading(true)

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/audio/transcribe_and_summarize_all`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      )
      setResults({
        general: response.data.generalSummary,
        minutes: response.data.meetingMinutes,
        simple: response.data.simpleSummary,
      })
      setShowActions(false)
      setSummariesGenerated(true)
      alert('Summaries generated successfully.')
    } catch (error) {
      console.error('Error generating summaries:', error)
      alert('Failed to generate summaries. Please try again.')
    } finally {
      setIsLoading(false)
    }
  }

  const exportAsWord = () => {
    const doc = new Document({
      sections: [
        {
          properties: {},
          children: [
            new Paragraph({ text: 'General Summary' }),
            new Paragraph({ text: results.general }),
            new Paragraph({ text: 'Meeting Minutes' }),
            new Paragraph({ text: results.minutes }),
            new Paragraph({ text: 'Simple Summary' }),
          ],
        },
      ],
    })

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, 'meeting_summary.docx')
    })
  }

  const exportAsPDF = () => {
    const doc = new jsPDF()
    doc.text('General Summary', 10, 10)
    doc.text(results.general, 10, 20)
    doc.text('Meeting Minutes', 10, 100)
    doc.text(results.minutes, 10, 110)
    doc.text('Simple Summary', 10, 200)
    doc.text(results.simple, 10, 210)
    doc.save('meeting_summary.pdf')
  }

  const sendEmail = () => {
    const subject = encodeURIComponent('Meeting Summary')
    const body = encodeURIComponent(
      `General Summary:\n${results.general}\n\nMeeting Minutes:\n${results.minutes}\n\nSimple Summary:\n${results.simple}`
    )
    window.location.href = `mailto:?subject=${subject}&body=${body}`
  }

  const copyToClipboard = () => {
    const text = `General Summary:\n${results.general}\n\nMeeting Minutes:\n${results.minutes}\n\nSimple Summary:\n${results.simple}`
    navigator.clipboard.writeText(text).then(() => {
      alert('Copied to clipboard!')
    })
  }

  return (
    <>
      {isLimitLoading
        ? ''
        : limitReached && (
            <div className='bg-red-100 text-red-700 border border-red-400 px-4 py-3 flex flex-col sm:flex-row gap-4 justify-between items-start sm:items-center'>
              You have used up your available minutes, please upgrade to
              continue.
              <Link
                to='/plans'
                className='bg-red-500 text-white px-2.5 py-2 rounded'
              >
                Upgrade
              </Link>
            </div>
          )}
      <div className='flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4'>
        <h1 className='text-3xl font-bold mb-6'>Record Meeting</h1>
        <p className='text-lg mb-4'>
          Click the button below to begin recording the meeting.
        </p>
        <p className='text-lg mb-4'>
          The recorded audio will be automatically saved after you stop the
          recording.
        </p>

        {isLoading ? (
          <LoadingSpinner />
        ) : isRecording ? (
          <div className='flex flex-col items-center'>
            <div className='flex items-center mb-4 space-x-4'>
              <button
                onClick={isPaused ? handleRecordResume : handleRecordPause}
                className={`px-4 py-2 rounded-lg text-white ${
                  isPaused
                    ? 'bg-green-500 hover:bg-green-600'
                    : 'bg-yellow-500 hover:bg-yellow-600'
                }`}
              >
                {isPaused ? 'Resume' : 'Pause'}
              </button>
              <button
                onClick={() => handleRecordStop(false)}
                className='bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600'
              >
                Stop
              </button>
            </div>
            <p className='text-xl'>
              {Math.floor(recordingTime / 60)}:
              {('0' + (recordingTime % 60)).slice(-2)}
            </p>
          </div>
        ) : (
          <button
            onClick={handleRecordStart}
            disabled={limitReached}
            className='bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 disabled:cursor-not-allowed disabled:bg-blue-400'
          >
            Start Recording
          </button>
        )}

        {showActions && (
          <div className='mt-6 w-full max-w-lg bg-white p-6 rounded-lg shadow-md'>
            <h2 className='text-xl font-bold mb-4 text-center'>
              Recording completed successfully. Generate summaries for your
              recently concluded meeting:
            </h2>
            <div className='flex flex-col items-center'>
              <button
                onClick={handleGenerateSummaries}
                className='bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 mb-6'
              >
                Generate All Summaries
              </button>
            </div>
          </div>
        )}
        {showModal && (
          <Modal isOpen={showModal}>
            <div className='mt-6 w-full max-w-lg p-6 rounded-lg'>
              {recordExceeded && (
                <div className='bg-red-100 text-red-700 border border-red-400 px-4 py-3 rounded mb-4'>
                  You have reached your recording limit.
                </div>
              )}
              <p className='text-center font-medium text-sm'>
                Please save your recording now.
              </p>
              <div className='flex flex-col justify-center mt-4 gap-4'>
                <button
                  onClick={() => saveAndGenerateTranscript(true)}
                  className='bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600'
                >
                  Save Recording And Transcribe
                </button>
                <button
                  onClick={() => {
                    saveAndGenerateTranscript(false)
                  }}
                  className='bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600'
                >
                  Transcribe Only
                </button>
                <button
                  onClick={handleCancel}
                  className='bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600'
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal>
        )}

        {summariesGenerated && (
          <>
            <div className='summary-results space-y-8 mt-8 w-full max-w-4xl'>
              <SummarySection
                title='General Summary'
                content={results.general}
              />
              <SummarySection
                title='Meeting Minutes'
                content={results.minutes}
              />
              <SummarySection
                title='Simple Summary for Non-Professionals'
                content={results.simple}
              />
            </div>
            <div className='export-actions flex justify-center space-x-4 mb-8'>
              <button
                onClick={exportAsWord}
                className='bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600'
                title='Export as Word'
              >
                <AiOutlineFileWord size={24} />
              </button>
              <button
                onClick={exportAsPDF}
                className='bg-red-500 text-white p-2 rounded-lg hover:bg-red-600'
                title='Export as PDF'
              >
                <AiOutlineFilePdf size={24} />
              </button>
              <button
                onClick={sendEmail}
                className='bg-orange-500 text-white p-2 rounded-lg hover:bg-orange-600'
                title='Email'
              >
                <AiOutlineMail size={24} />
              </button>
              <button
                onClick={copyToClipboard}
                className='bg-green-500 text-white p-2 rounded-lg hover:bg-green-600'
                title='Copy'
              >
                <AiOutlineCopy size={24} />
              </button>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default RecordingPage
